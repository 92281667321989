import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { tracking } from 'noddi-provider';
import { NoddiButton, NoddiTextAreaInput, useNoddiToast } from 'noddi-ui';
import { useState } from 'react';

import MeasureTiresForm from './MeasureTiresForm';

interface MeasureWheelsProps {
  routeItemId: number;
  bookingItemId: number;
  carId: number;
}

const MeasureTiresAndCompleteBookingItem = ({ routeItemId, carId, bookingItemId }: MeasureWheelsProps) => {
  const { noddiToast } = useNoddiToast();
  const [skipCarWheelMeasurementsReason, setSkipCarWheelMeasurementsReason] = useState<string | null>(null);
  const [isUnableToMeasure, setIsUnableToMeasure] = useState(false);
  const { mutateAsync: markBookingItemAsComplete, isPending: isCompleteBookingItemLoading } = noddiAsync.usePost({
    type: URLKeys.postMarkBookingItemsAsComplete,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: {
            routeItemId
          }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { mutateAsync: addTireMeasurements, isPending: isAddTireMeasurementsPending } = noddiAsync.usePost({
    type: URLKeys.postAddCarTireMeasurements,
    queryConfig: {
      onSuccess: async () => {
        tracking.track('unableToMeasureTiresCompleteBooking', { skipCarWheelMeasurementsReason });
        await markBookingItemAsComplete({ bookingItemId });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  return (
    <div>
      {isUnableToMeasure ? (
        <div>
          <p className='mb-2'>What is the reason for not being able to measure the tires?</p>
          <NoddiTextAreaInput
            rows={3}
            onChange={(event) => {
              setSkipCarWheelMeasurementsReason(event.target.value);
            }}
          />
          <NoddiButton
            fullWidth
            className='mt-4'
            loading={isCompleteBookingItemLoading}
            disabled={!skipCarWheelMeasurementsReason}
            variant='success'
            onClick={async () => {
              await markBookingItemAsComplete({ bookingItemId, skipCarWheelMeasurementsReason });
            }}
          >
            Finish car
          </NoddiButton>
          <div className='mt-4 flex justify-center'>
            <NoddiButton variant='link' onClick={() => setIsUnableToMeasure(false)}>
              Go back to measurement
            </NoddiButton>
          </div>
        </div>
      ) : (
        <>
          <p>Please measure the tread depth to finalize car. Remember to measure two times.</p>
          <MeasureTiresForm
            ctaText='Finish car'
            carId={carId}
            bookingItemId={bookingItemId}
            addTireMeasurements={addTireMeasurements}
            isAddTireMeasurementsPending={isAddTireMeasurementsPending}
          />
          <div className='mt-4 flex justify-center'>
            <NoddiButton variant='link' onClick={() => setIsUnableToMeasure(true)}>
              Unable to measure tread depth?
            </NoddiButton>
          </div>
        </>
      )}
    </div>
  );
};

export default MeasureTiresAndCompleteBookingItem;
