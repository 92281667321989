import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressProps, LicenseAreaListItem, UserData, UserDataProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import {
  ErrorPage,
  LoadingScreen,
  NoddiButton,
  NoddiFormAddressPicker,
  NoddiFormSelect,
  NoddiFormTextInput,
  SectionSpacer
} from 'noddi-ui';
import { useEffect, useRef } from 'react';

import userRegistrationFormSchema from './validators';

interface IFormInput {
  firstName: string;
  surname: string;
  email: string;
  addressStart: AddressProps | null;
  addressEnd: AddressProps | null;
  licenseAreaIds: number[];
  languageCodes: string[];
  password: string;
  confirmPassword: string;
}

const getLicenseAreaOptions = (licenseAreas: LicenseAreaListItem[]) => {
  return licenseAreas
    ? licenseAreas.map((licenseArea: LicenseAreaListItem) => {
        return { value: licenseArea.id, label: licenseArea.name };
      })
    : [];
};

interface UserRegistrationFormProps {
  phoneNumber: string;
  onLoginSuccess?: (userData: UserDataProps) => void;
  trackUserIdentity: (userData: UserData) => void;
}

const UserRegistrationForm = ({ phoneNumber, onLoginSuccess, trackUserIdentity }: UserRegistrationFormProps) => {
  const { loginUser } = useAuthContext();

  const { data: licenseAreas, isPending: isLicenseAreasLoading } = noddiAsync.useGet({
    type: URLKeys.getLicenseAreas
  });

  const initialValues = {
    firstName: '',
    surname: '',
    email: '',
    addressStart: null,
    addressEnd: null,
    licenseAreaIds: [],
    languageCodes: ['EN'],
    password: '',
    confirmPassword: ''
  };

  const onSubmit = (data: IFormInput) => {
    if (!data.addressEnd || !data.addressStart) {
      return;
    }

    createUser({
      firstName: data.firstName,
      lastName: data.surname,
      phoneNumber,
      isWorker: true,
      startAddress: data.addressStart,
      endAddress: data.addressEnd,
      email: data.email,
      licenseAreaIds: data.licenseAreaIds,
      languageCodes: data.languageCodes,
      password: data.password,
      confirmPassword: data.confirmPassword
    });
  };

  // use form state
  const { mutateAsync: createUser, isPending } = noddiAsync.usePost({
    type: URLKeys.postUser,
    queryConfig: {
      onSuccess: (data) => {
        loginUser(data.data); // this is actually a user sign-in
        onLoginSuccess?.(data.data);
        trackUserIdentity(data.data.user);
      }
    }
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  if (isLicenseAreasLoading) {
    return <LoadingScreen />;
  }
  if (!licenseAreas) {
    return <ErrorPage />;
  }

  const licenseAreaOptions = getLicenseAreaOptions(licenseAreas);

  const languageOptions = [
    { label: 'Norwegian', value: 'NO' },
    { label: 'English', value: 'EN' }
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={userRegistrationFormSchema()}>
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography variant='h4'>User information</Typography>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput ref={ref} name='firstName' label='First name' />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='surname' label='Surname' />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='email' label='Email' placeholder='test@example.com' />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput autoComplete='new-password' name='password' label='Password' type='password' />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput
              autoComplete='new-password'
              name='confirmPassword'
              label='Confirm password'
              type='password'
            />{' '}
          </SectionSpacer>
          <Typography marginTop={4} variant='subtitle1'>
            At which license area can you work?
          </Typography>
          <NoddiFormSelect name='licenseAreaIds' label='License area' options={licenseAreaOptions} multiple fullWidth />
          <Typography marginTop={3} variant='subtitle1'>
            Which languages do you speak?
          </Typography>
          <NoddiFormSelect name='languageCodes' label='Language' options={languageOptions} multiple fullWidth />
          <Typography marginTop={3} variant='subtitle1'>
            At what place do you want to start you work (include street number)?
          </Typography>
          <NoddiFormAddressPicker name='addressStart' label='Address' />
          <Typography marginTop={3} variant='subtitle1'>
            At what place do you want to end you work (include street number)?
          </Typography>
          <NoddiFormAddressPicker name='addressEnd' label='Address' />

          <NoddiButton
            style={{ marginTop: '24px' }}
            type='submit'
            fullWidth
            disabled={!isValid}
            loading={isSubmitting || isPending}
          >
            Create user
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

export default UserRegistrationForm;
