import { MenuItem, Select, Typography } from '@mui/material';
import { SalesCar } from 'noddi-async/src/types';
import { NoddiButton, NoddiChip, NoddiLinearProgressLoader } from 'noddi-ui';
import { Dispatch, SetStateAction } from 'react';

import useGetSalesItems, { SalesItemDto } from '../../hooks/apiHooks/useGetSalesItems';

type Props = {
  carSearchResult: SalesCar;
  licenseAreaId: number;
  onAddService: ({ salesItemIds }: { salesItemIds: number[] }) => void;
  setSelectedCar?: Dispatch<SetStateAction<SalesCar | null>>;
  selectedSalesItems: SalesItemDto[];
  setSelectedSalesItems: Dispatch<SetStateAction<SalesItemDto[]>>;
  excludeSalesItemIds?: number[];
  fetchForNewCar: boolean;
};

const SelectSalesItem = ({
  carSearchResult,
  licenseAreaId,
  onAddService,
  setSelectedSalesItems,
  fetchForNewCar,
  setSelectedCar,
  selectedSalesItems,
  excludeSalesItemIds
}: Props) => {
  const { isLoading, data: salesItemsData } = useGetSalesItems({
    license_area_id: licenseAreaId,
    car: carSearchResult,
    enabled: true,
    fetchForNewCar
  });

  if (isLoading) {
    return <NoddiLinearProgressLoader />;
  }

  const salesItemOptions = salesItemsData?.map((salesItem) => {
    return {
      value: salesItem.id,
      label: `${salesItem.name} (${salesItem.price} kr)`
    };
  });

  const options = salesItemOptions?.filter((item) => !excludeSalesItemIds?.includes(item.value));
  const selectedValues = selectedSalesItems.map((item) => item.id);

  return options ? (
    <>
      <Typography marginTop={2} variant='subtitle1'>
        Select services
      </Typography>
      <Select
        fullWidth
        multiple
        value={selectedValues}
        name='salesItems'
        label='Select sales items'
        onChange={(e) => {
          const selectedValues = e.target.value as number[];
          const salesItems = salesItemsData?.filter((item) => selectedValues.includes(item.id)) ?? [];

          setSelectedSalesItems(salesItems);
        }}
        renderValue={(selected) => {
          return selected.map((value) => (
            <div key={value} className='m-3'>
              <NoddiChip label={options?.find((option) => option.value === value)?.label} />
            </div>
          ));
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <NoddiButton
        style={{ marginTop: '24px' }}
        fullWidth
        disabled={selectedValues.length === 0}
        onClick={() => {
          if (setSelectedCar) {
            setSelectedCar(carSearchResult);
          }
          onAddService({ salesItemIds: selectedValues });
        }}
      >
        <Typography variant='body1'>Confirm</Typography>
      </NoddiButton>
    </>
  ) : (
    <Typography marginTop={2} variant='subtitle1'>
      No sales items found
    </Typography>
  );
};

export default SelectSalesItem;
